<template>
  <v-container fluid class="hero">
    <v-row>
      <v-col cols="12" md="6">
        <v-tabs v-model="tab" class="app-tabs-header">
          <v-tab v-for="(user, index) in users.records" :key="index">
            {{ user.firstName }} {{ user.lastName }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in payments.records" :key="item">
            <!-- <div v-for="item in items" :key=item> -->
            <!-- <div> -->
            <v-row class="app-tabs-rows">
              <v-col cols="6">
                <h1>R{{ item.amount }}.00</h1>
              </v-col>
              <v-col cols="6">
                <p>{{ item.processBy }}</p>
                <p @click="$router.push(`/user/paymentProcess/${item.id}`)">
                  <b>Process</b>
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- </div> -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <!-- <v-col cols="12" md="6" class="absolute-center">
        <v-btn
          color="#7D72F5"
          class="app-btn2 tertiary white--text"
          style="margin-left: auto; margin-right: auto"
          @click="$router.push('/user/paymentCreate')"
        >
          Create Payment
          <v-icon>mdi-cash-multiple</v-icon>
        </v-btn>
      </v-col> -->

      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="400" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
// import moment from ;

export default {
  components: {},
  name: "Schedule Payment",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      show: false,
      showMore: false,
      selectedItem: 1,
      model: "tab-1",
      users: [],
      payments: [],
      paymentsData: [],
      page: 1,
      id: "",
      tab: null,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              role
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          page: this.page,
          query: { consultant: this.me.id, role: "employee" },
        };
      },
      result(response) {
        // this.id = response.data.users.records.id
        let data = this.users.records;
        data.forEach((e) => {
          // this.id = e.id
          this.employees.push(e.firstName);
        });
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    payments: {
      query: gql`
        query Payments($limit: Int!, $skip: Int!, $query: JSON!) {
          payments(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              employer
              employerName
              employee
              employeeName
              status
              processBy
              period
              amount
              createdAt
              updatedAt
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          skip: 0,
          query: { status: "scheduled" },
        };
      },
      result(response) {
        let payments = response.data?.payments?.records;
        this.paymentsData.push(payments);
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.app-tabs-rows {
  padding: 0 30px;
}
.app-tabs-rows h1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;

  color: #7d72f5;
}
.app-tabs-rows .col {
  padding: 20px 20px;
  margin-top: 10px;
  height: auto;
}
.app-tabs-rows p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #3f3d56;
}

.app-tabs-rows b {
  font-weight: 700;
}
</style>
